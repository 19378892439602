<template>
  <v-row>
    <v-col cols="12">
      <p>
        FL比率とFL実数値の事業年度内推移を確認できます。
        単月詳細は指でタップすると詳細を開けます。
      </p>
      <p>
        ※
        事業年度は会計データと連動しています。事業年度が適切に表示されていない場合には
        <router-link to="/contact">
          お問い合わせページ
        </router-link>
        よりCP事務局までお問い合わせ下さい。
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "FLRatioDetailsModal"
};
</script>

<style scoped></style>
